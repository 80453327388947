























































































































































import Vue from 'vue';
import { DetectClick } from '@/utils/DetectClick';

export default Vue.extend({
  props: {
    session: { type: Object, required: true },
    duplicateSession: { type: Function, required: true },
    editSession: { type: Function, required: true },
    deleteSession: { type: Function, required: true },
    pauseSession: { type: Function, required: true },
    closeSessionOptionsMenu: { type: Function, required: true },
    toggleSessionOptionsMenu: { type: Function, required: true },
    isPausePlayVisible: Boolean,
    showSessionOptionsMenu: Boolean,
    computedShareLink: { type: String, required: true },
    showCopySuccessToast: { type: Function, required: true },
    showCopyFailureToast: { type: Function, required: true },
  },
  directives: {
    DetectClick,
  },
});
